// Products.jsx
import React, { useState } from 'react';
import RowUser from './components/useRows'; 
import useGetProducts from './hooks/AddRows';
import Popup from './components/Popup';
import PopupProduct from './components/popup_product';
import useGetCatalogs from '../Category/hooks/AddRows';


const Products = () => {
    const { products, reloadData } = useGetProducts();
    const { catalog } = useGetCatalogs();
    const [isPopupOpen, setIsPopupOpen] = useState(false);
    const [isPopupProductOpen, setIsPopupProductOpen] = useState(false);
    const [currentProductId, setCurrentProductId] = useState(null);

    const [filters, setFilters] = useState({
        id: '',
        category: '',
        gender: '',
        name: '',
        brand: '',
        cost: '',
        color: '',
        user: ''
    });

    const handleFilterChange = (e) => {
        const { name, value } = e.target;
        setFilters((prevFilters) => ({
            ...prevFilters,
            [name]: value
        }));
    };

    const getTranslatedTag = (tag) => {
        const catalogItem = catalog.find(item => item.tag === tag);
        return catalogItem ? catalogItem.name : tag;
    };

    function translateCategory(category) {
        if (category.endsWith('_man')) return 'Мужчины';
        if (category.endsWith('_girl')) return 'Женщины';
        return category; // Если категория не оканчивается на '_man' и '_girl', возвращаем исходное значение
    }
    const filteredProducts = products.filter(product => 
        (product.id.toString().includes(filters.id) || filters.id === '') &&
        (getTranslatedTag(product.tag).toLowerCase().includes(filters.category.toLowerCase()) || filters.category === '') &&
        (translateCategory(product.tag).toLowerCase().includes(filters.gender.toLowerCase()) || filters.gender === '') &&
        ((product.name || '').toLowerCase().includes(filters.name.toLowerCase()) || filters.name === '') &&
        ((product.brand || '').toLowerCase().includes(filters.brand.toLowerCase()) || filters.brand === '') &&
        (filters.cost === '') &&
        ((product.color || '').toLowerCase().includes(filters.color.toLowerCase()) || filters.color === '') &&
        ((product.product_user || '').toLowerCase().includes(filters.user.toLowerCase()) || filters.user === '')
    );

    const handlePopupOpen = () => {
        setIsPopupOpen(true);
    };

    const handlePopupClose = () => {
        setIsPopupOpen(false);
        reloadData();
    };

    const handlePopupProductOpen = (productId) => {
        console.log('click')
        setCurrentProductId(productId);
        setIsPopupProductOpen(true);
    };

    const handlePopupProductClose = () => {
        setIsPopupProductOpen(false);
        reloadData();
    };

    return (
        <main>
            <div className="header">
                <div className="left">
                    <h1>Товары</h1>
                    <ul className="breadcrumb">
                        <li>
                            <a href="#">
                                Главное
                            </a>
                        </li>
                        /
                        <li>
                            <a href="#" className="active">Карточки товаров</a>
                        </li>
                    </ul>
                </div>
            </div>
            <ul className="insights">
                {/* <Card className="bx bx-child" h3='1' p="Пользователей Всего" />
                <Card className="bx bx-child" h3={length_notapproved} p="Пользователей ожидающих подтверждения" /> */}
            </ul>
            <div className="bottom-data">
                <div className="orders">
                    <div className="header">
                        <i className='bx bx-receipt'></i>
                        <h3>Товары</h3>
                        <i className='bx bx-plus' onClick={handlePopupOpen}></i>
                        {/* <i className='bx bx-filter'></i>
                        <i className='bx bx-search'></i> */}
                        <i className='bx bx-refresh' onClick={reloadData}></i>
                    </div>
                    <table>
                        <thead>
                            <tr>
                                <th>ID</th>
                                <th>Категория</th>
                                <th>М\Ж</th>
                                <th>Название товара</th>
                                <th>Бренд</th>
                                <th>Стоимость</th>
                                <th>Цвет</th>
                                <th>На продаже у пользователя</th>
                            </tr>
                            <tr>
                                <th>
                                    <div className="filter">
                                        <input
                                            type="text"
                                            name="id"
                                            value={filters.id}
                                            onChange={handleFilterChange}
                                            placeholder="Фильтр по ID"
                                        />
                                    </div>
                                </th>
                                <th>
                                    <div className="filter">
                                        <input
                                            type="text"
                                            name="category"
                                            value={filters.category}
                                            onChange={handleFilterChange}
                                            placeholder="Фильтр по категории"
                                        />
                                    </div>
                                </th>
                                <th>
                                    <div className="filter">
                                        <input
                                            type="text"
                                            name="gender"
                                            value={filters.gender}
                                            onChange={handleFilterChange}
                                            placeholder="Фильтр по М/Ж"
                                        />
                                    </div>
                                </th>
                                <th>
                                    <div className="filter">
                                        <input
                                            type="text"
                                            name="name"
                                            value={filters.name}
                                            onChange={handleFilterChange}
                                            placeholder="Фильтр по названию товара"
                                        />
                                    </div>
                                </th>
                                <th>
                                    <div className="filter">
                                        <input
                                            type="text"
                                            name="brand"
                                            value={filters.brand}
                                            onChange={handleFilterChange}
                                            placeholder="Фильтр по бренду"
                                        />
                                    </div>
                                </th>
                                <th>
                                    <div className="filter">
                                        <input
                                            type="text"
                                            name="cost"
                                            value={filters.cost}
                                            onChange={handleFilterChange}
                                            placeholder="Фильтр по стоимости"
                                        />
                                    </div>
                                </th>
                                <th>
                                    <div className="filter">
                                        <input
                                            type="text"
                                            name="color"
                                            value={filters.color}
                                            onChange={handleFilterChange}
                                            placeholder="Фильтр по цвету"
                                        />
                                    </div>
                                </th>
                                <th>
                                    <div className="filter">
                                        <input
                                            type="text"
                                            name="user"
                                            value={filters.user}
                                            onChange={handleFilterChange}
                                            placeholder="Фильтр по пользователю"
                                        />
                                    </div>
                                </th>
                            </tr>
                        </thead>
                        <tbody>
                            {/* <RowUser id='1' tag='тэг' name='Сумка' brand='LV' cost='100 000' color='Черный' user='2734893487'/> */}
                            {filteredProducts.map(product => (
                                <RowUser
                                    onClick={() => handlePopupProductOpen(product.id)}
                                    key={product.id}
                                    id={product.id}
                                    tag={getTranslatedTag(product.tag)}
                                    name={product.name}
                                    brand={product.brand}
                                    cost={product.cost}
                                    color={product.color}
                                    user={product.product_user}
                                    girlandman={translateCategory(product.tag)}
                                />
                            ))}
                        </tbody>
                    </table>
                </div>
            </div>
            {isPopupOpen && <Popup
            onClose={handlePopupClose}
            size_p={''} 
            color_p={''} 
            barnd_p={''} 
            name_p={''} 
            cost_p={''} 
            status_p={''} 
            kit_p={''} 
            tag_p={''} 
            product_user_p={''} 
            out_cost_p={''}
            isEditing = {false} 
            />}
            {isPopupProductOpen && (<PopupProduct productId={currentProductId} onClose={handlePopupProductClose}/>
            )}
        </main>
    );
};

export default Products;
