// ./components/Popup.jsx
import React, { useState } from 'react';
import useAddProducts from '../hooks/useAddProducts';
import useGetCatalogs from '../../Category/hooks/AddRows';



const Popup = ({ onClose,productId, size_p , color_p, barnd_p, name_p, cost_p, status_p, kit_p, tag_p, product_user_p, out_cost_p, image_path_p, isEditing, isCopy}) => {
    const [formData, setFormData] = useState({
        size: `${size_p}`,
        color: `${color_p}`,
        brand: `${barnd_p}`,
        name: `${name_p}`,
        cost: `${cost_p}`,
        status: `${status_p}`,
        kit: `${kit_p}`,
        tag: `${tag_p}`,
        news: 0,
        image_new: 0,
        product_user: `${product_user_p}`,
        image: null, // Стейт для хранения информации о файле
        out_cost: `${out_cost_p}`,
        requestImagepath: `${image_path_p}`,
        productId: `${productId}`
    });
    const [isImageFieldDisabled, setIsImageFieldDisabled] = useState(isEditing);

    const { addProduct, loading, error } = useAddProducts();

    const { catalog } = useGetCatalogs();

    const handleChange = (e) => {
        if (e.target.type === 'file') {
            // Сохраняем файл в стейт
            setFormData({
                ...formData,
                image: e.target.files[0] // Присваиваем объект File
            });
        } else {
            // Обычное обновление текстовых полей
            setFormData({
                ...formData,
                [e.target.name]: e.target.value
            });
        }
    };

    const handleAddProduct = async () => {
        try {
            const formDataToSend = new FormData();
            formDataToSend.append('size', formData.size);
            formDataToSend.append('color', formData.color);
            formDataToSend.append('brand', formData.brand);
            formDataToSend.append('name', formData.name);
            formDataToSend.append('cost', formData.cost);
            formDataToSend.append('status', formData.status);
            formDataToSend.append('kit', formData.kit);
            formDataToSend.append('tag', formData.tag);
            formDataToSend.append('product_user', formData.product_user);
            formDataToSend.append('image', formData.image); 
            formDataToSend.append('out_cost', formData.out_cost);
            formDataToSend.append('news', formData.news);
            formDataToSend.append('image_new', formData.image_new);
            if (productId){formDataToSend.append('productId', formData.productId)}
            if (image_path_p){formDataToSend.append('requestImagepath', formData.requestImagepath)}
            
            const result = await addProduct(formDataToSend);
            console.log('Продукт успешно добавлен:', result);
            onClose(); // Закрываем попап после успешного добавления
        } catch (error) {
            console.error('Ошибка при добавлении продукта:', error);
        }
    };
    function translateCategory(category) {
        if (category.endsWith('man')) return 'Мужчины';
        if (category.endsWith('girl')) return 'Женщины';
        return category; // Если категория не оканчивается на '_man' и '_girl', возвращаем исходное значение
    }
    
    const handleCheckboxChange = (e) => {
        const { name, checked } = e.target;
        setFormData({
            ...formData,
            [name]: checked ? 1 : 0,
        });
    };
    

    return (
        <div className="fixed inset-0 flex items-center justify-center bg-black bg-opacity-50 z-50">
            <div className="bg-white p-8 rounded-lg w-full max-w-lg max-h-[60%] overflow-y-auto top-20">
                <h2 className="text-2xl mb-4">
                    {isEditing ? `Изменение товара ${productId}` : isCopy ? 'Создание копии' : 'Добавить товар'}

                    
                </h2>
                <form>
                    <div className="mb-4">
                        <label className="block text-gray-700 text-sm font-bold mb-2">Категория</label>
                        <select
                            className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                            name="tag"
                            value={formData.tag}
                            onChange={handleChange}
                        >
                            <option value="" disabled>Выберите категорию</option>
                            {catalog.map((item) => (
                            <option key={item.tag} value={item.tag}>
                                {item.name} {translateCategory(item.category)}
                            </option>
                            ))}
                        </select>
                    </div>

                    <div className="mb-4">
                        <label className="block text-gray-700 text-sm font-bold mb-2">Название</label>
                        <input 
                        className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline" 
                        type="text" 
                        placeholder="Название"
                        name="name"
                        value={formData.name}
                        onChange={handleChange} />
                    </div>

                    <div className="mb-4">
                        <label className="block text-gray-700 text-sm font-bold mb-2">Брэнд</label>
                        <input 
                        className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline" 
                        type="text" 
                        placeholder="Брэнд"
                        name="brand"
                        value={formData.brand}
                        onChange={handleChange} />
                    </div>
                    <div className="mb-4">
                        <label className="block text-gray-700 text-sm font-bold mb-2">Стоимость</label>
                        <input 
                        className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline" 
                        type="text" 
                        placeholder="Стоимость"
                        name="cost"
                        value={formData.cost}
                        onChange={handleChange} />
                    </div>
                    <div className="mb-4">
                        <label className="block text-gray-700 text-sm font-bold mb-2">Статус товара</label>
                        <input 
                        className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline" 
                        type="text" 
                        placeholder="Статус товара"
                        name="status"
                        value={formData.status}
                        onChange={handleChange} />
                    </div>
                    <div className="mb-4">
                        <label className="block text-gray-700 text-sm font-bold mb-2">Размер</label>
                        <input 
                        className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline" 
                        type="text" 
                        placeholder="Размер"
                        name="size"
                        value={formData.size}
                        onChange={handleChange} />
                    </div>
                    <div className="mb-4">
                        <label className="block text-gray-700 text-sm font-bold mb-2">Комплект товара</label>
                        <input 
                        className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline" 
                        type="text" 
                        placeholder="Комплект товара"
                        name="kit"
                        value={formData.kit}
                        onChange={handleChange} />
                    </div>
                    <div className="mb-4">
                        <label className="block text-gray-700 text-sm font-bold mb-2">Цвет</label>
                        <input 
                        className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline" 
                        type="text" 
                        placeholder="Цвет"
                        name="color"
                        value={formData.color}
                        onChange={handleChange} />
                    </div>
                    <div className="mb-4">
                        <label className="block text-gray-700 text-sm font-bold mb-2">ID пользователя (если есть)</label>
                        <input 
                        className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline" 
                        type="text" 
                        placeholder="ID пользователя"
                        name="product_user"
                        value={formData.product_user}
                        onChange={handleChange} />
                    </div>
                    <div className="mb-4">
                        <label className="block text-gray-700 text-sm font-bold mb-2">Зачеркнутая цена</label>
                        <input 
                        className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline" 
                        type="text" 
                        placeholder="Зачеркнутая цена"
                        name="out_cost"
                        value={formData.out_cost}
                        onChange={handleChange} />
                    </div>
                    <div className="mt-2">
                        <label className="inline-flex items-center">
                            <input
                                type="checkbox"
                                className="form-checkbox h-5 w-5 text-blue-600 accent-pink-500 transition duration-150 ease-in-out"
                                name="news"
                                checked={formData.news === 1}
                                onChange={handleCheckboxChange}
                            />
                            <span className="ml-2 text-gray-700">Отображать в новинках</span>
                        </label>
                    </div>
                {(isEditing)&&(
                    <div className="mt-2">
                        <img
                            src={`https://konsierz-backend.plutus-fin.ru/${image_path_p}`}
                            alt={name_p}
                            className="w-full h-64 object-cover rounded-t-lg"
                            
                    />
                    <p>{image_path_p}</p>
                    </div>
                )}



                {/* {(isEditing || isCopy)&& (
                    <div className="mt-2">
                        <label className="inline-flex items-center">
                            <input
                                type="checkbox"
                                className="form-checkbox h-5 w-5 text-blue-600 transition duration-150 ease-in-out"
                                name="image_new"
                                checked={formData.image_new === 1}
                                onChange={handleCheckboxChange}
                                defaultChecked={!isImageFieldDisabled} // Отмечаем галочку, если поле активно
                            />
                            <span className="ml-2 text-gray-700">Я хочу поменять картинку</span>
                        </label>
                    </div>
                )} */}
                    <div className="mb-4">
                        <label className="block text-gray-700 text-sm font-bold mb-2">Поле для вставки картинки</label>
                        <input 
                            className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline" 
                            type="file"
                            name="image"
                            onChange={handleChange} // Обработчик для выбора файла
                            // disabled={isImageFieldDisabled}
                        />
                    </div>
                    <div className="flex items-center justify-between">
                        <button
                            className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline"
                            type="button"
                            onClick={onClose}
                        >
                            Закрыть
                        </button>
                        <button
                            className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline"
                            type="button"
                            onClick={handleAddProduct}
                        >

                            {isEditing ? 'Изменить' : 'Добавить'}
                        </button>
                    </div>
                </form>
                {error && <p style={{ color: 'red' }}>{error}</p>}
            </div>
        </div>
    );
};

export default Popup;
